.fondoAmarillo {
  background: #fcfbe3 !important; }

.fondoRojo {
  background: #ffe8e8 !important; }

.fondoRojoClaro {
  background: #fcefef !important; }

.fondoNaranja {
  background: #fff3e0 !important; }

.fondoNaranjaClaro {
  background: #fcf8f2 !important; }

.filaFormulario {
  margin-bottom: 0.75rem !important; }

.detalle .card .card-header {
  min-height: 40px !important; }

.detalle .card .card-body {
  padding: .2rem 2.25rem !important; }

.resumen .card-header {
  min-height: 36px !important; }

.resumen .card-body {
  padding: 1rem 2.25rem !important; }
  .resumen .card-body .resumenFila {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 15px; }
  .resumen .card-body .resumenFilaSubtotal {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 15px;
    font-weight: 900 !important; }

.descuentos .descuentosFila {
  margin-bottom: 0 !important; }
  .descuentos .descuentosFila label {
    padding-top: 3px !important; }

#totalOferta .totalOfertaLinea {
  margin-bottom: 10px !important; }
  #totalOferta .totalOfertaLinea .dotted {
    border-bottom: 1px dotted #ccc; }

@media (min-width: 992px) {
  .brand {
    background-color: #031a26;
    box-shadow: none; }
    .brand .btn i {
      color: #494b74; }
    .brand .btn .svg-icon svg g [fill] {
      transition: fill 0.3s ease;
      fill: #494b74; }
    .brand .btn .svg-icon svg:hover g [fill] {
      transition: fill 0.3s ease; }
    .brand .btn.active .svg-icon svg g [fill], .brand .btn:hover .svg-icon svg g [fill] {
      transition: fill 0.3s ease;
      fill: #3699FF; }
    .brand .btn.active .svg-icon svg:hover g [fill], .brand .btn:hover .svg-icon svg:hover g [fill] {
      transition: fill 0.3s ease; }
    .brand .btn.active i, .brand .btn:hover i {
      color: #3699FF; } }

@media (max-width: 991.98px) {
  .header-mobile {
    background-color: #1a1a27; }
    .header-mobile .burger-icon span {
      background-color: #484f66; }
      .header-mobile .burger-icon span::before, .header-mobile .burger-icon span::after {
        background-color: #484f66; }
    .header-mobile .burger-icon:hover span {
      background-color: #3699FF; }
      .header-mobile .burger-icon:hover span::before, .header-mobile .burger-icon:hover span::after {
        background-color: #3699FF; }
    .header-mobile .burger-icon-active span {
      background-color: #3699FF; }
      .header-mobile .burger-icon-active span::before, .header-mobile .burger-icon-active span::after {
        background-color: #3699FF; }
    .header-mobile .btn i {
      color: #484f66; }
    .header-mobile .btn .svg-icon svg g [fill] {
      transition: fill 0.3s ease;
      fill: #484f66; }
    .header-mobile .btn .svg-icon svg:hover g [fill] {
      transition: fill 0.3s ease; }
    .header-mobile .btn.active .svg-icon svg g [fill], .header-mobile .btn:hover .svg-icon svg g [fill] {
      transition: fill 0.3s ease;
      fill: #3699FF; }
    .header-mobile .btn.active .svg-icon svg:hover g [fill], .header-mobile .btn:hover .svg-icon svg:hover g [fill] {
      transition: fill 0.3s ease; }
    .header-mobile .btn.active i, .header-mobile .btn:hover i {
      color: #3699FF; } }
